const mainColor = '#01618e'; // rgb(1, 97, 142)

export default theme => ({
  languageSelectorContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  languageIcon: {
    marginBottom: '0.3rem',
    marginRight: '6px',
    color: mainColor,
  },
  selectContainer: {
    color: mainColor,
    backgroundColor: 'transparent',
    '&:before': {
      borderBottom: 'none',
    },
    '&:after': {
      borderBottom: 'none',
    },
    '&:hover:not(.Mui-disabled):before': {
      borderBottom: 'none',
    },
  },
  caretIcon: {
    color: mainColor,
  },
  menuItem: {
    color: mainColor,
    '&.Mui-selected': {
      display: 'none',
    },
    '&:hover': {
      backgroundColor: '#efefef',
    },
  },
  menuPaper: {
    transform: 'translate(-1.5rem, 4rem) !important',

    [theme.breakpoints.between('1024', '1040')]: {
      transform: 'translate(0rem, 4rem) !important',
    },

    [theme.breakpoints.between('1040', '1060')]: {
      transform: 'translate(-0.5rem, 4rem) !important',
    },

    [theme.breakpoints.between('xs', 'md')]: {
      transform: 'translate(0.5rem, 4rem) !important',
    },
  },
});

import React from 'react';
import { FormattedMessage } from 'react-intl';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import PropTypes from 'prop-types';
import LanguageIcon from '@material-ui/icons/Language';
import makeStyles from '@material-ui/styles/makeStyles';
import styles from './LanguageSelector.styles';

const useStyles = makeStyles(styles);

export const LanguageSelector = ({
  supportedLanguages,
  selectedLocale,
  switchLanguages,
  defaultLocale,
}) => {
  const classes = useStyles();

  const [language, setLanguage] = React.useState(
    selectedLocale ?? defaultLocale
  );
  const handleChange = event => {
    let selectedLanguage = event.target.value;
    setLanguage(selectedLanguage);
    switchLanguages(selectedLanguage);
    document.documentElement.lang = selectedLanguage;
  };

  const options = supportedLanguages.map(languageCode => ({
    value: languageCode,
    label: `localeSwitcher_dropdown_label_${languageCode}`,
  }));

  return (
    <div className={classes.languageSelectorContainer}>
      <LanguageIcon className={classes.languageIcon} fontSize="medium" />
      <Select
        className={classes.selectContainer}
        value={language}
        onChange={handleChange}
        defaultValue={selectedLocale ?? defaultLocale}
        inputProps={{
          classes: {
            icon: classes.caretIcon,
          },
        }}
        MenuProps={{
          PaperProps: {
            className: classes.menuPaper,
          },
        }}
      >
        {options.map(option => (
          <MenuItem
            className={classes.menuItem}
            key={option.value}
            value={option.value}
          >
            <FormattedMessage id={option.label} />
          </MenuItem>
        ))}
      </Select>
    </div>
  );
};

LanguageSelector.propTypes = {
  supportedLanguages: PropTypes.arrayOf(PropTypes.string).isRequired,
  switchLanguages: PropTypes.func.isRequired,
  defaultLocale: PropTypes.string,
  selectedLocale: PropTypes.string,
};

export default LanguageSelector;

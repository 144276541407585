import { connect } from 'react-redux';
import { compose } from 'redux';
import { injectIntl } from 'react-intl';
import * as selectors from '../../selectors';
import Actions from '../../locales.actions';
import LocaleSwitcher from './LocalesSwitcher.component';
import * as configSelector from 'domains/core/config/selectors';

const mapStateToProps = state => ({
  selectedLocale: selectors.selectedLocale(state),
  supportedLanguages: configSelector.supportedLanguages(state),
  localesMessages: configSelector.localeMessages(state),
  defaultLocale: configSelector.defaultLocale(state),
});

const mapDispatchToProps = dispatch => ({
  switchLanguages: locale => dispatch(Actions.selectLocale(locale)),
});

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  injectIntl
)(LocaleSwitcher);

import React from 'react';
import PropTypes from 'prop-types';
import Button from 'components/Button';
import LanguageSelector from 'components/LanguageSelector';

export const getNextLocale = (supportedLanguages, selectedLocale) => {
  let index = supportedLanguages.findIndex(item => item === selectedLocale);
  index += 1;

  if (index >= supportedLanguages.length) {
    index = 0;
  }
  return supportedLanguages[index];
};

const Component = ({
  selectedLocale,
  supportedLanguages,
  switchLanguages,
  defaultLocale,
}) => {
  const nextLocale = getNextLocale(supportedLanguages, selectedLocale);

  if (supportedLanguages && supportedLanguages.length === 2) {
    return (
      <Button
        variant={'text'}
        isPrimary={false}
        data-cmp="LocaleSwitcher"
        text={`localeSwitcher_button_label_${selectedLocale}`}
        onClick={() => {
          switchLanguages(nextLocale);
          document.documentElement.lang = nextLocale;
        }}
      />
    );
  } else if (supportedLanguages && supportedLanguages.length > 2) {
    return (
      <div>
        <LanguageSelector
          switchLanguages={switchLanguages}
          selectedLocale={selectedLocale}
          supportedLanguages={supportedLanguages}
          defaultLocale={defaultLocale}
        />
      </div>
    );
  } else {
    return null;
  }
};

Component.propTypes = {
  defaultLocale: PropTypes.string.isRequired,
  selectedLocale: PropTypes.string.isRequired,
  supportedLanguages: PropTypes.arrayOf(PropTypes.string).isRequired,
  switchLanguages: PropTypes.func.isRequired,
};

export default Component;

import React from 'react';
import PropTypes from 'prop-types';
import { IntlProvider, addLocaleData } from 'react-intl';
import enLocaleData from 'react-intl/locale-data/en';
import frLocaleData from 'react-intl/locale-data/fr';
import hiLocaleData from 'react-intl/locale-data/hi';
import paLocaleData from 'react-intl/locale-data/pa';
import zhLocaleData from 'react-intl/locale-data/zh';
import * as localesModels from '../../locales.model';
import { getMessagesFromConfig, getLocale } from './LocalesProvider.helper';

// TODO: addLocaleData has been removed since 3.0.0--beta6
// will fix it in next release
addLocaleData([
  ...enLocaleData,
  ...frLocaleData,
  ...hiLocaleData,
  ...paLocaleData,
  ...zhLocaleData,
]);

const LocalesProvider = ({
  defaultLocale,
  dynamicMessages,
  selectedLocale,
  selectLocale,
  localeMessages,
  supportedLanguages,
  forceLocale,
  children,
}) => {
  if (!selectedLocale) {
    selectLocale(getLocale({ supportedLanguages, forceLocale, defaultLocale }));
    return null;
  }

  return (
    <IntlProvider
      locale={selectedLocale}
      messages={getMessagesFromConfig(
        { ...dynamicMessages, ...localeMessages },
        selectedLocale
      )}
      defaultLocale={defaultLocale}
      data-cmp="locales-provider"
    >
      {children}
    </IntlProvider>
  );
};

LocalesProvider.propTypes = {
  defaultLocale: PropTypes.string.isRequired,
  dynamicMessages: PropTypes.object.isRequired,
  selectLocale: PropTypes.func.isRequired,
  supportedLanguages: PropTypes.arrayOf(PropTypes.string).isRequired,
  forceLocale: PropTypes.string,
  localeMessages: PropTypes.object,
  selectedLocale: localesModels.SelectedLocaleModel,
};

export default LocalesProvider;
